<template>
  <div>
    <div v-if="!hideOverlay" id="overlay">
      <input v-model="password" type="password" id="overlay-input" placeholder="Enter password" />
      <button id="overlay-button" @click="unlockScreen(password)">Submit</button>
    </div>
    <v-form @submit.prevent="createTeam">
      <v-text-field v-model="team.name" label="Name"></v-text-field>
      <v-select v-model="team.sport" :items="sports" label="Sport"></v-select>
      <v-text-field v-model="team.code" label="Team Code"></v-text-field>
      <v-text-field v-model="team.colour" label="Team Colour"></v-text-field>
      <v-btn type="submit" color="primary">Create Team</v-btn>
    </v-form>
  </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        team: {
          name: '',
          sport: '',
          code: '',
          colour: '#'
        },
        sports: ['Football', 'Hurling', 'Rugby', 'Soccer'],
        password: '',
        hideOverlay: false
      };
    },
    methods: {
      createTeam() {
        this.$store.dispatch('createTeam', this.team);
      },
      unlockScreen(password) {
        if (password == 'briandillons') {
          this.hideOverlay = true;
        } else {
          this.password = null;
        }
      }
    }
  };
  </script>
  
  <style scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 9999;
  /* High z-index to stay on top */
}

#overlay input {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  color: white;
}

#overlay button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
